import { getAccessToken } from './tokenSlice';

export const prepareHeaders = (headers: Headers, { getState }: { getState: () => any }) => {
    const token = getAccessToken(getState());

    if (token) {
        headers.set('Authorization', `Bearer ${token}`);
        headers.set('Content-Type', 'application/json');
    }

    return headers;
};
