import { ThunkDispatch } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { Dispatch } from 'redux';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { RootState } from '../../configuration/setup/store';
import { accessTokenStored } from '../../configuration/tokenHandling/tokenSlice';
import { isReadinessNotifiedSelector, readinessNotified } from './appSlice';

// Events received from web-workshop
export const TOKEN_RENEWED = 'WEB_WORKSHOP/TOKEN_RENEWED';
const FEATURE_TOGGLE_CHANGED = 'WEB_WORKSHOP/FEATURE_TOGGLE_CHANGED';
// Events sent to parent frontend
export const CLOSE_BUTTON = 'STENCIL_PROJECT/CLOSE_BUTTON';
const MFE_IS_LOADED = 'MFE/BOOTSTRAP/MFE_IS_LOADED';

interface MessageEvent<T> {
    type: string;
    payload: T;
}

export const sendMessage = (message: MessageEvent<any>) => {
    const referrer = document.referrer;
    if (referrer) {
        window.parent.postMessage(message, referrer);
    }
};

export const receiveMessage = (event: any, dispatch: ThunkDispatch<Dispatch<any>, RootState, any>) => {
    const { type: actionType, payload } = event.data;
    if (!actionType) {
        // eslint-disable-next-line no-console
        console.log('Received event in stencil-project without type: ', event);
        return;
    }
    switch (actionType) {
        case TOKEN_RENEWED:
            const { token } = payload;
            dispatch(accessTokenStored(token));
            break;
        case FEATURE_TOGGLE_CHANGED:
            const previousValue = window.localStorage.getItem(payload.key) || 'false';
            if (payload.enabled === 'true') {
                window.localStorage.setItem(payload.key, payload.enabled);
            } else {
                window.localStorage.removeItem(payload.key);
            }
            if (previousValue !== payload.enabled) {
                window.location.reload();
            }
            break;
        default:
            // eslint-disable-next-line no-console
            console.log('Received event in stencil-project with type not handled: ', actionType);
            break;
    }
};

// eslint-disable-next-line react/display-name
const MessageHandler = React.memo(() => {
    const dispatch = useAppDispatch();
    const isReadinessNotified = useAppSelector(isReadinessNotifiedSelector);
    useEffect(() => {
        const listenerCallback = (event: any) => receiveMessage(event, dispatch);
        window.addEventListener('message', listenerCallback, false);
        if (!isReadinessNotified) {
            sendMessage({
                type: MFE_IS_LOADED,
                payload: {
                    mfeName: 'stencil-project',
                },
            });
            dispatch(readinessNotified());
        }
        return () => window.removeEventListener('message', listenerCallback, false);
    }, []);

    return null;
});

export default MessageHandler;
