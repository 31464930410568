import { configureStore } from '@reduxjs/toolkit';

import appReducer from '../../features/app/appSlice';
import { examplesApi } from '../../services/examplesApi';
import langReducer from '../lang/langSlice';
import loginReducer from '../login/loginSlice';
import tokenReducer from '../tokenHandling/tokenSlice';
import configReducer from './configSlice';

export const store = configureStore({
    reducer: {
        config: configReducer,
        lang: langReducer,
        app: appReducer,
        login: loginReducer,
        tokenHandling: tokenReducer,
        [examplesApi.reducerPath]: examplesApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(examplesApi.middleware),
});

// Infer the `RootState` and `RootDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;
