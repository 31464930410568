import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { config } from '../config';
import { prepareHeaders } from '../configuration/tokenHandling/prepareHeaders';

export const examplesApi = createApi({
    reducerPath: 'examplesApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.backend.BACKEND_URI, prepareHeaders }),
    endpoints: (build) => ({
        getExamples: build.query<any, void>({
            query: () => ({
                url: '/stencil-project-examples',
                responseHandler: async (response: Response) => {
                    return response.text();
                },
            }),
        }),
    }),
});

export const { useGetExamplesQuery } = examplesApi;
